import { useTranslation } from "react-i18next";
import Header from "Components/Header";
import Tabs from "Components/Tabs";
import CalendarIcon from "Assets/Icons/CalendarIcon";
import BillableIcon from "Assets/Icons/BillableIcon";
import UserIcon from "Assets/Icons/UserIcon";
import RoundingIcon from "Assets/Icons/RoundingIcon";
import GroupsIcon from "Assets/Icons/GroupsIcon";
import TagIcon from "Assets/Icons/TagIcon";
import ClientsIcon from "Assets/Icons/ClientsIcon";
import DatePicker from "Components/DatePicker";
import { useEffect, useState } from "react";
import SelectV2 from "Components/SelectV2";
import { DateRange } from "react-day-picker";
import Space from "Components/Space";
import { ReportGroupBy } from "Apis/Models/ReportModel";
import { ReportBillable } from "Hooks/UseReporting";
import moment from "moment";
import { useAuthProvider } from "Providers/AuthProvider";
import RequiresPremiumModal from "Components/RequiresPremiumModal";
import {
    getAdvancedReportCSV,
    getAdvancedReportPdf,
    useReportHistory,
    useReportsFilters,
    useReportV2,
    useSaveReportDefaults
} from "Apis/ReportsApi";
import { DateUtils } from "Utils/DateUtils";
import List from "Components/List/List";
import FlexRow from "Components/FlexRow";
import Duration from "Components/Duration";
import BillableActive from "Assets/Icons/BillableActive";
import BillableInactive from "Assets/Icons/BillableInactive";
import Tooltip from "Components/Tooltip";
import Edit from "Assets/Icons/Edit";
import { Registration } from "Apis/Models/Registration.type";
import ReportGroupListItem from "Pages/Reports/Components/ReportGroupListItem";
import styles from './ReportPage.module.scss';
import { getNumberAsCurrency } from "Utils/NumberUtils";
import MoneyBagIcon from "Assets/Icons/MoneyBagIcon";
import StopWatchIcon from "Assets/Icons/StopWatchIcon";
import DownloadIcon from "Assets/Icons/DownloadIcon";
import { openSuccessNotification } from "Utils/NotificationUtils";
import PlusIcon from "Assets/Icons/PlusIcon";
import RegistrationModal from "Features/Registrations/Components/RegistrationModal";
import Dropdown from "../../Components/Dropdown";
import Spinner from "../../Components/Spinner";
import PaperPDFIcon from "../../Assets/Icons/PaperPDFIcon";
import PaperCSVIcon from "../../Assets/Icons/PaperCSVIcon";


// Default date range is last month if today is before the 7. of the month
const defaultDateRange = new Date().getDate() < 7 ?
    {from: moment().subtract(1, 'months').startOf('month').toDate(), to: moment().subtract(1, 'months').endOf('month').toDate()} :
    {from: moment().startOf('month').toDate(), to: moment().endOf('month').toDate() };

export const ReportPage = () => {

    const { t } = useTranslation();

    const quickDateRanges = [
        { key: t('today'), value: [moment().startOf('day').toDate(), moment().endOf('day').toDate()] } ,
        { key: t('thisWeek'), value: [moment().startOf('week').toDate(), moment().endOf('week').toDate()] },
        { key: t('lastWeek'), value: [moment().startOf('week').subtract(1, 'week').toDate(), moment().endOf('week').subtract(1, 'week').toDate()] },
        { key: t('thisMonth'), value: [moment().startOf('month').toDate(), moment().endOf('month').toDate()] } ,
        { key: t('lastMonth'), value: [moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()] } ,
        { key: t('thisYear'), value: [moment().startOf('year').toDate(), moment().endOf('year').toDate()] } ,
        { key: t('lastYear'), value: [moment().subtract(1, 'year').startOf('year').toDate(), moment().subtract(1, 'year').endOf('year').toDate()] } ,
    ]

    const { userContext } = useAuthProvider();

    const { data: reportFilters, isFetched: hasFetchedReportFilters } = useReportsFilters();
    const { data: reportHistory } = useReportHistory();
    const { mutateAsync: saveDefaultReportSettingsMutation } = useSaveReportDefaults();

    const [dateRange, setDateRange] = useState<DateRange>(defaultDateRange);

    const [customerFilterValue, setCustomerFilterValue] = useState<string>('');
    const [selectedCustomer, setSelectedCustomer] = useState<number>();

    const [projectFilterValue, setProjectFilterValue] = useState<string>('');
    const [selectedProject, setSelectedProject] = useState<number>();

    const [groupByFilterValue, setGroupByFilterValue] = useState<string>('');
    const [selectedGroupBy, setSelectedGroupBy] = useState<ReportGroupBy | null>(null);

    const [tagFilterValue, setTagFilterValue] = useState<string>('');
    const [selectedTag, setSelectedTag] = useState<number[]>([]);
    const [reverseTagSearch, setReverseTagSearch] = useState<boolean>(false);

    const [roundingFilterValue, setRoundingFilterValue] = useState<string>('');
    const [selectedRounding, setSelectedRounding] = useState<number>();

    const [userFilterValue, setUserFilterValue] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<number>();

    const [billableFilterValue, setBillableFilterValue] = useState<string>('');
    const [selectedBillable, setSelectedBillable] = useState<ReportBillable>('All');

    const [ isDownloadingReport, setDownloadingReport ] = useState<boolean>(false);

    const [timeFormat, setTimeFormat] = useState<'Hours' | 'Decimal'>('Hours')

    const { data: report, isLoading: isLoadingReport, refetch: refetchReport} = useReportV2({
        fromDate: DateUtils.getDateOnlyString(dateRange.from!),
        toDate: DateUtils.getDateOnlyString(dateRange.to!),
        customerId: selectedCustomer,
        projectId: selectedProject,
        userId: selectedUser,
        billable: selectedBillable,
        groupBy: selectedGroupBy,
        rounding: selectedRounding,
        tags: selectedTag,
        reverseTagSearch: reverseTagSearch,
        enabled: hasFetchedReportFilters
    });

    useEffect(() => {
        if (reportFilters?.defaultReportSettings == null) {
            return;
        }

        if (!userContext?.hasActiveLicense) {
            return;
        }

        setSelectedRounding(reportFilters.defaultReportSettings.defaultRounding);
        setSelectedGroupBy(reportFilters.defaultReportSettings.defaultGroupBy === "None" || reportFilters.defaultReportSettings.defaultGroupBy === undefined ? null : reportFilters.defaultReportSettings.defaultGroupBy);
    }, [reportFilters, userContext])

    useEffect(() => {
        if (userContext && userContext.accessLevel !== 200) {
            setSelectedUser(userContext.userId)
        }
    }, [userContext])

    const customerOptions = () => {
        return reportFilters?.customers?.filter(x => x.name.toLowerCase().includes(customerFilterValue) || x.vatNumber?.includes(customerFilterValue)).map((customer) => {
            return (
                <SelectV2.Option label={customer.name} key={customer.id} value={customer.id} />
            )
        })
    }

    const projectOptions = () => {
        return reportFilters?.customers?.filter(x => selectedCustomer ? selectedCustomer === x.id : true).map((customer) => {

            let projects = customer.projects ?? [];

            projects = projects.filter(x => x.name.toLowerCase().includes(projectFilterValue));

            if (projects.length === 0) {
                return undefined;
            }

            const options = projects.sort((a,b) => a.name > b.name ? 1 : -1).map((project) => {
                return (
                    <SelectV2.Option label={project.name} key={project.id} value={project.id} />
                )
            })

            return (
                <SelectV2.Group key={customer.id} label={customer.name}>
                    {options}
                </SelectV2.Group>
            )
        })
    }

    const groupByOptions = () => {
        const options = [
            { label: t('report:noGroup'), value: null },
            { label: t('report:user'), value: 'User' },
            { label: t('report:project'), value: 'Project' },
            { label: t('report:customer'), value: 'Customer' },
            { label: t('report:description'), value: 'Description' },
            { label: t('report:descriptionAndProject'), value: 'DescriptionWithProject' },
            { label: t('report:date'), value: 'Date' },
        ]

        return options?.filter(x => x.label.toLowerCase().includes(groupByFilterValue)).map((option) => {
            return (
                <SelectV2.Option label={option.label} key={option.value} value={option.value} />
            )
        })
    }

    const tagOptions = () => {
        return reportFilters?.tags?.filter(x => x.text.toLowerCase().includes(tagFilterValue)).map((tag) => {
            return (
                <SelectV2.Option label={tag.text} key={tag.id} value={tag.id} />
            )
        })
    }

    const roundingOptions = () => {
        let number = 0;

        const values: any[] = [];

        while (number <= 60) {
            if (number.toString().includes(roundingFilterValue)) {
                values.push(<SelectV2.Option value={number} key={number} label={number === 0 ? t('report:noRounding') : `${number} ${t('report:minutes')}`}></SelectV2.Option>);
            }

            number++;
        }

        return values;
    }

    const userOptions = () => {
        if (userContext?.accessLevel === 200) {
            return reportFilters?.users?.filter(x => x.name?.toLowerCase().includes(userFilterValue) || x.eMail.toLowerCase().includes(userFilterValue)).map((user) => {
                return (
                    <SelectV2.Option label={user.name ?? user.eMail} key={user.id} value={user.id} />
                )
            })
        }

        return [
            <SelectV2.Option label={userContext!.name ?? userContext!.email} key={userContext?.userId} value={userContext?.userId} />
        ]
    }

    const billableOptions = () => {
        const options = [
            { label: t('report:all'), value: 'All' },
            { label: t('report:billable'), value: 'Billable' },
            { label: t('report:notBillable'), value: 'NotBillable' },
        ]

        return options?.filter(x => x.label.toLowerCase().includes(billableFilterValue)).map((option) => {
            return (
                <SelectV2.Option label={option.label} key={option.value} value={option.value} />
            )
        })
    }

    const getSubLabel = (tab: string) => {
        switch (tab) {
            case 'date':
                return dateRange?.from && dateRange?.to ? `${moment(dateRange.from).format('L')} - ${moment(dateRange.to).format('L')}` : '';
            case 'customer':
                let values: string[] = [];
                if (selectedCustomer) {
                    values.push(reportFilters?.customers?.find(x => x.id === selectedCustomer)!.name!);
                }
                if (selectedProject) {
                    values.push(reportFilters?.customers?.flatMap(x => x.projects)?.find(x => x.id === selectedProject)!.name!)
                }

                return values.join(' | ');
            case 'user':
                if (selectedUser) {
                    let user = reportFilters?.users?.find(x => x.id === selectedUser)!;

                    return !user ? '' : user.name ?? user.eMail
                }
                return "";
            case 'tag':
                if (selectedTag.length > 0) {
                    const tags = reportFilters?.tags.filter(x => selectedTag.includes(x.id)).map(x => x.text).join(', ');
                    return `${!reverseTagSearch ? t('has') : t('doesntHave')} ${tags}`;
                }
                return "";
            case "groupBy":
                if (selectedGroupBy) {
                    return t(`report:${selectedGroupBy.toLowerCase()}`);
                }
                return "";
            case 'rounding':
                if (selectedRounding) {
                    return selectedRounding === 0 ? t('report:noRounding') : `${selectedRounding} ${t('report:minutes')}`;
                }
                return "";
            case 'billable':
                if (selectedBillable) {
                    return selectedBillable === 'All' ? t('report:all') : selectedBillable === 'Billable' ? t('report:billable') : t('report:notBillable');
                }
        }
    }

    const saveReportDefaults = async (type: "Rounding" | "GroupBy") => {
        const data = { ...reportFilters?.defaultReportSettings };

        switch (type) {
            case "Rounding":
                data.defaultRounding = selectedRounding;
                break;
            case "GroupBy":
                data.defaultGroupBy = selectedGroupBy!;
                break;
        }

        await saveDefaultReportSettingsMutation(data);

        openSuccessNotification(t('success'), t('report:defaultReportSettingsSaved'));
    }

    const tabs = [
        {
            key: "calendar",
            label: t('report:calendarLabel'),
            subLabel: getSubLabel('date'),
            active: true,
            icon: <CalendarIcon />,
            content: (
                <div className={styles.grid}>
                    <DatePicker allowClear={false} placeholder={t('report:selectDates')} name={"date"} dateRange={dateRange} onChange={setDateRange} mode={'range'} />

                    <div>
                        <span className={styles.functionsHeader}>
                            {t('report:predefinedDates')}
                        </span>
                        <div className={styles.tagContainer}>
                            {quickDateRanges.map(x => (
                                <div key={x.key} className={`${styles.tag} ${x.value[0].toString() === dateRange?.from?.toString() && x.value[1].toString() === dateRange?.to?.toString() ? styles.active : ''} `} onClick={() => setDateRange({from: x.value[0], to: x.value[1]})} >
                                    {x.key}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: "customer",
            label: t('report:customerLabel'),
            subLabel: getSubLabel('customer'),
            active: !!selectedCustomer || !!selectedProject,
            icon: <ClientsIcon />,
            allowClear: true,
            onClear: () => {
                setSelectedCustomer(undefined);
                setSelectedProject(undefined)
            },
            content: (
                <div className={styles.grid}>
                    <div className={styles.displayGrid} style={{gridTemplateColumns: 'repeat(2, calc(50% - 10px))'}}>
                        <SelectV2 styles={{minWidth: 300}} value={selectedCustomer} name={"CustomerId"} allowClear={true} onFilter={x => setCustomerFilterValue(x.toLowerCase())} onChange={value => {
                            setSelectedCustomer(value);
                            if (selectedProject && reportFilters?.customers?.flatMap(x => x.projects).find(x => x.id === selectedProject)?.customerId !== value) {
                                setSelectedProject(undefined);
                            }
                        }} placeholder={"Vælg kunde"}>
                            {customerOptions()}
                        </SelectV2>

                        <SelectV2 value={selectedProject} name={"ProjectId"} allowClear={true} onFilter={x => setProjectFilterValue(x.toLowerCase())} onChange={value => setSelectedProject(value)} placeholder={"Vælg projekt"}>
                            {projectOptions()}
                        </SelectV2>
                    </div>

                    <div className={styles.displayGrid} style={{gridTemplateColumns: 'repeat(2, calc(50% - 20px))'}}>
                        <div>
                            <span className={styles.functionsHeader}>
                                {t('report:lastUsedCustomers')}
                            </span>
                            <div className={styles.tagContainer}>
                                {Array.from(new Set(reportHistory?.filter(x => !!x.customerId).slice(0, 3).map(x => x.customerId))).map(x => (
                                    <div key={x} className={`${styles.tag} ${selectedCustomer === x ? styles.active : ''}`} onClick={() => {
                                        setSelectedCustomer(x);
                                        if (selectedProject && reportFilters?.customers?.flatMap(x => x.projects).find(x => x.id === selectedProject)?.customerId !== x) {
                                            setSelectedProject(undefined);
                                        }
                                    }}>
                                        {reportFilters?.customers?.find(y => y.id === x)?.name}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            <span className={styles.functionsHeader}>
                                {t('report:lastUsedProjects')}
                            </span>
                            <div className={styles.tagContainer}>
                                {Array.from(new Set(reportHistory?.filter(x => !!x.projectId).slice(0, 3).map(x => x.projectId))).map(x => (
                                    <div key={x} className={`${styles.tag} ${selectedProject === x ? styles.active : ''}`} onClick={() => {
                                        setSelectedCustomer(undefined);
                                        setSelectedProject(x);
                                    }}>
                                        {reportFilters?.customers?.flatMap(y => y.projects).find(y => y.id === x)?.name}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            )
        },
        {
            key: "user",
            label: t('report:userLabel'),
            subLabel: getSubLabel('user'),
            active: !!selectedUser,
            icon: <UserIcon />,
            allowClear: true,
            onClear: () => {
                setSelectedUser(undefined);
            },
            content: (
                <div className={styles.grid}>
                    <SelectV2 value={selectedUser} name={"User"} allowClear={true} onFilter={x => setUserFilterValue(x.toLowerCase())} onChange={value => setSelectedUser(value)} placeholder={t('report:selectUser')}>
                        {userOptions()}
                    </SelectV2>

                    <div>
                        <span className={styles.functionsHeader}>
                            {t('report:lastUsedUsers')}
                        </span>
                        <div className={styles.tagContainer}>
                            {Array.from(new Set(reportHistory?.filter(x => !!x.userId).slice(0, 3).map(x => x.userId))).map(x => (
                                <div key={x} className={`${styles.tag} ${selectedUser === x ? styles.active : ''}`} onClick={_ => setSelectedUser(x)}>
                                    {reportFilters?.users?.find(y => y.id === x)?.name ?? reportFilters?.users?.find(y => y.id === x)?.eMail}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )
        },
        {
            key: "group",
            label: t('report:groupLabel'),
            subLabel: getSubLabel('groupBy'),
            active: !!selectedGroupBy,
            icon: <GroupsIcon />,
            allowClear: true,
            onClear: () => {
                setSelectedGroupBy(null);
            },
            content: (
                <Space direction={"horizontal"} size={"large"}>
                    <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:reporting.groupingInReports')}>
                        <SelectV2
                            disabled={!userContext?.hasActiveLicense}
                            value={selectedGroupBy}
                            name={"GroupBy"}
                            allowClear={true}
                            onFilter={x => setGroupByFilterValue(x.toLowerCase())}
                            onChange={value => setSelectedGroupBy(value)} placeholder={"Vælg gruppering"}
                            suffix={{label: t('report:setDefault'), onClick: () => saveReportDefaults("GroupBy"), isShown: true, active: selectedGroupBy !== reportFilters?.defaultReportSettings?.defaultGroupBy}}
                        >
                            {groupByOptions()}
                        </SelectV2>
                    </RequiresPremiumModal>
                </Space>
            )
        },
        {
            key: "tag",
            label: t('report:tagLabel'),
            subLabel: getSubLabel('tag'),
            active: selectedTag.length > 0,
            icon: <TagIcon />,
            allowClear: true,
            onClear: () => {
                setSelectedTag([]);
            },
            content: (
                <Space direction={"horizontal"} size={"large"}>
                    <SelectV2 value={reverseTagSearch} name={"reverseTagSearch"} allowClear={false} onChange={value => setReverseTagSearch(value)} placeholder={"Vælg tag"}>
                        <SelectV2.Option value={false} label={t('has')}></SelectV2.Option>
                        <SelectV2.Option value={true} label={t('doesntHave')}></SelectV2.Option>
                    </SelectV2>

                    <SelectV2 value={selectedTag} multiple name={"tags"} allowClear={true} onFilter={x => setTagFilterValue(x.toLowerCase())} onChange={(value) => setSelectedTag([...value])} placeholder={"Vælg tag"}>
                        {tagOptions()}
                    </SelectV2>
                </Space>
            )
        },
        {
            key: "rounding",
            label: t('report:rounding'),
            subLabel: getSubLabel('rounding'),
            active: !!selectedRounding,
            icon: <RoundingIcon />,
            allowClear: true,
            onClear: () => {
                setSelectedRounding(0);
            },
            content: (
                <Space direction={"horizontal"} size={"large"}>
                    <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:reporting.roundingInReports')}>
                        <SelectV2
                            disabled={!userContext?.hasActiveLicense}
                            value={selectedRounding}
                            name={"Rounding"}
                            allowClear={true}
                            onFilter={x => setRoundingFilterValue(x.toLowerCase())}
                            onChange={value => setSelectedRounding(value)} placeholder={"Vælg afrunding"}
                            suffix={{label: t('report:setDefault'), onClick: () => saveReportDefaults("Rounding"), isShown: true, active: selectedRounding !== reportFilters?.defaultReportSettings?.defaultRounding}}
                        >
                            {roundingOptions()}
                        </SelectV2>
                    </RequiresPremiumModal>
                </Space>
            )
        },
        {
            key: "billable",
            label: t('report:billableLabel'),
            subLabel: getSubLabel('billable'),
            active: !!selectedBillable,
            icon: <BillableIcon />,
            content: (
                <Space direction={"horizontal"} size={"large"}>
                    <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRegistrations')}>
                        <SelectV2 disabled={!userContext?.hasActiveLicense} value={selectedBillable} name={"Billable"} onFilter={x => setBillableFilterValue(x.toLowerCase())} onChange={value => setSelectedBillable(value)} placeholder={"Vælg afrunding"}>
                            {billableOptions()}
                        </SelectV2>
                    </RequiresPremiumModal>
                </Space>
            )
        },
    ]

    const columns = [
        {
            size: 'max-content',
            title: t('date'),
            customRender: (_, record) => <b>{moment(record.startedAt).format('DD-MM/YYYY')}</b>,
            dataIndex: 'date'
        },
        {
            title: t('customer'),
            textRender: (_, record) => record.project?.customer?.name,
            dataIndex: 'project'
        },
        {
            title: t('project'),
            textRender: (_, record) => record.project?.name,
            dataIndex: 'project'
        },
        {
            title: t('description'),
            dataIndex: 'description'
        },
        {
            size: 'max-content',
            title: t('user'),
            textRender: (_, record) => reportFilters?.users?.find(x => x.id === record.userId)?.name ?? '',
            dataIndex: 'project'
        },
        {
            size: 'max-content',
            title: t('start / stop'),
            customRender: (_, record) => <div>{moment(record.startedAt).format('HH:mm')} - {moment(record.stoppedAt).format('HH:mm')}</div>,
            dataIndex: 'project'
        },
        {
            size: 'max-content',
            title: t('total'),
            customRender: (_, record) => (
                <FlexRow justify={"space-between"}>
                    <FlexRow justify={"center"} style={{marginRight: '15px'}}>
                        {record.billable ? (
                            <BillableActive/>
                        ) : (
                            <BillableInactive/>
                        )}
                    </FlexRow>
                    <Duration style={{fontWeight: 600}} duration={record.duration} timeType={timeFormat} />
                </FlexRow>),
            dataIndex: 'project'
        },
        {
            size: 'min-content',
            title: '',
            customRender: (_, record) => (
                <div className={"flex align-center clickable"} onClick={() => registrationSelected(record)}>
                    <Tooltip title={t('registrations:updateRegistration')}>
                        <Edit />
                    </Tooltip>
                </div>
            )
        }
    ]

    const [openGroupKey, setOpenGroupKey] = useState<string>('');
    const [selectedRegistration, setSelectedRegistration] = useState<Registration | undefined>(undefined);
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    const onRegistrationModalOk = () => {
        refetchReport();
        setSelectedRegistration(undefined);
    }

    const registrationSelected = (registration: Registration) => {
        setSelectedRegistration(registration);
        setModalVisible(true);
    }

    const cancelModal = () => {
        setModalVisible(false);
        setSelectedRegistration(undefined);
        refetchReport();
    }

    const downloadPdf = async (language, detailed, timeType: 'decimal' | 'hours') => {
        setDownloadingReport(true)

        try {
            let fileName = `${t('registrations:reporting.entries')} - ${moment(dateRange.from).format('DD-MM/YYYY')} - ${moment(dateRange.to).format('DD-MM/YYYY')}`

            if (selectedCustomer) {
                fileName = `${fileName} - ${reportFilters!.customers?.find(x => x.id === selectedCustomer)!.name}`
            }

            if (selectedProject) {
                const project = reportFilters!.customers?.flatMap(x => x.projects).find(x => x.id === selectedProject);
                fileName = `${fileName} - ${project!.name}`
            }

            fileName = `${fileName}.pdf`;

            const a = {
                fromDate: DateUtils.getDateOnlyString(dateRange.from!),
                toDate: DateUtils.getDateOnlyString(dateRange.to!),
                customerId: selectedCustomer,
                projectId: selectedProject,
                userId: selectedUser,
                billable: selectedBillable,
                groupBy: selectedGroupBy,
                rounding: selectedRounding,
                tags: selectedTag,
                reverseTagSearch: reverseTagSearch,
                enabled: hasFetchedReportFilters,
                language: language,
                detailed: detailed,
                timeType: timeType
            }

            await getAdvancedReportPdf(a, fileName);
        }
        catch (e) {

        }
        finally {
            setDownloadingReport(false);
        }
    }

    const downloadCSV = async (format: 'Hour' | 'Decimal' | 'Second') => {
        setDownloadingReport(true)

        try {
            let fileName = `${t('registrations:reporting.entries')} - ${moment(dateRange.from).format('DD-MM/YYYY')} - ${moment(dateRange.to).format('DD-MM/YYYY')}`

            if (selectedCustomer) {
                fileName = `${fileName} - ${reportFilters!.customers?.find(x => x.id === selectedCustomer)!.name}`
            }

            if (selectedProject) {
                const project = reportFilters!.customers?.flatMap(x => x.projects).find(x => x.id === selectedProject);
                fileName = `${fileName} - ${project!.name}`
            }

            fileName = `${fileName}.csv`;

            const a = {
                fromDate: DateUtils.getDateOnlyString(dateRange.from!),
                toDate: DateUtils.getDateOnlyString(dateRange.to!),
                customerId: selectedCustomer,
                projectId: selectedProject,
                userId: selectedUser,
                billable: selectedBillable,
                groupBy: selectedGroupBy,
                rounding: selectedRounding,
                tags: selectedTag,
                reverseTagSearch: reverseTagSearch,
                enabled: hasFetchedReportFilters,
                timeFormat: format
            }

            await getAdvancedReportCSV(a, fileName);
        }
        catch (e) {

        }
        finally {
            setDownloadingReport(false);
        }
    }

    const totalContainer = (
        <div className={styles.totalContainer}>
            <div className={styles.totalTime}>
                <RequiresPremiumModal enabled={!userContext?.hasActiveLicense} title={t('registrations:billableRates')}>
                    <Space direction={"horizontal"} align={"center"} size={"medium"}>
                        <MoneyBagIcon fill={"#0d60f8"} width={35} height={35} />

                        <div>
                            <div className={styles.label}>
                                {t('report:totalBillable')}
                            </div>
                            <div className={styles.value}>
                                {(userContext?.hasActiveLicense && userContext?.accessLevel === 200) ? getNumberAsCurrency(report?.registrations.map(x => x.totalBillable).reduce((a, b) => a + b, 0) ?? 0) : '-'}
                            </div>
                        </div>
                    </Space>
                </RequiresPremiumModal>
            </div>
            <div className={styles.totalBillable}>
                <Space direction={"horizontal"} align={"center"} size={"medium"}>
                    <StopWatchIcon fill={"#fff"} width={35} height={35}  />

                    <div>
                        <div className={styles.label}>
                            {t('report:totalTime')}
                        </div>
                        <div className={styles.value}>
                            <Duration style={{fontWeight: 800}} duration={report?.registrations.map(x => x.duration).reduce((a, b) => a! + b!, 0)} timeType={timeFormat} />
                        </div>
                    </div>
                </Space>
            </div>
        </div>
    )

    return (
        <div>
            <Header text={t('report:reports.title')} />

            <Space direction={"vertical"} size={"large"}>
                <Tabs tabs={tabs} />

                <FlexRow justify={"space-between"}>
                    <FlexRow direction={"column"} align={"start"}>
                        <div className={styles.functionsHeader}>
                            {t('report:functions')}
                        </div>
                        <FlexRow className={styles.functionButtons}>
                            <div>
                                <Dropdown toggle={isDownloadingReport ? <FlexRow justify={"center"} align={"center"}><Spinner /></FlexRow> : <DownloadIcon height={30} width={30}  />}>
                                    <div className={styles.dropDownContent}>
                                        <div className={styles.dropdownHeader}>
                                            {t('registrations:reports.downloadAsPdfWithHours')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadPdf('DA', false, 'hours')}>
                                            <PaperPDFIcon height={20} width={20} style={{marginRight: 10}} /> {t('registrations:reports.danish')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadPdf('EN', false, 'hours')}>
                                            <PaperPDFIcon height={20} width={20} style={{marginRight: 10}} /> {t('registrations:reports.english')}
                                        </div>

                                        <div className={styles.dropdownHeader}>
                                            {t('registrations:reports.downloadAsPdfWithDecimal')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadPdf('DA', false, 'decimal')}>
                                            <PaperPDFIcon height={20} width={20} style={{marginRight: 10}}/> {t('registrations:reports.danish')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadPdf('EN', false, 'decimal')}>
                                            <PaperPDFIcon height={20} width={20} style={{marginRight: 10}}/> {t('registrations:reports.english')}
                                        </div>

                                        <div className={styles.dropdownHeader}>
                                            {t('registrations:reports.downloadAsDetailedPdf')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadPdf('DA', true, 'hours')}>
                                            <PaperPDFIcon height={20} width={20} style={{marginRight: 10}}/> {t('registrations:reports.danish')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadPdf('EN', true, 'hours')}>
                                            <PaperPDFIcon height={20} width={20} style={{marginRight: 10}}/> {t('registrations:reports.english')}
                                        </div>

                                        <div className={styles.dropdownHeader}>
                                            {t('registrations:reports.downloadAsCSV')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadCSV('Hour')}>
                                            <PaperCSVIcon height={20} width={20} style={{marginRight: 10}} />  {t('registrations:reports.csvWithHours')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadCSV('Decimal')}>
                                            <PaperCSVIcon height={20} width={20} style={{marginRight: 10}} />  {t('registrations:reports.csvWithDecimal')}
                                        </div>
                                        <div className={styles.dropDownItem} onClick={() => downloadCSV('Second')}>
                                            <PaperCSVIcon height={20} width={20} style={{marginRight: 10}} />  {t('registrations:reports.csvWithSeconds')}
                                        </div>
                                    </div>
                                </Dropdown>
                            </div>
                            <div onClick={_ => setModalVisible(true)} >
                                <PlusIcon height={30} width={30}/>
                            </div>
                            <div onClick={_ => setTimeFormat(current => current === 'Hours' ? 'Decimal' : 'Hours')} className={styles.formatContainer}>
                                {timeFormat === 'Hours' ? '00:00' : '0,00'}
                            </div>
                        </FlexRow>
                    </FlexRow>
                    <div>
                        {totalContainer}
                    </div>
                </FlexRow>

                {!!report?.groupBy ? (
                    report.groupedRegistrations.map(x => <ReportGroupListItem key={`${x.label}-${x.subLabel}-${x.totalDuration}-${x.groupKey}`} itemOnClick={registrationSelected} onClick={() => setOpenGroupKey(x.label)} open={openGroupKey === x.label} group={x} timeFormat={timeFormat} />)
                ) : (<></>) }

                <div>
                    <List keyDataIndex={'id'} loading={isLoadingReport} emptyText={t('registrations:noRegistrationsFound')} data={report?.registrations} compact columns={columns} />
                </div>

                <FlexRow justify={"space-between"}>
                    <div>

                    </div>
                    <div>
                        {totalContainer}
                    </div>
                </FlexRow>
            </Space>

            <RegistrationModal visible={modalVisible} onCancel={cancelModal} registration={selectedRegistration} enableUserSelection={userContext?.accessLevel === 200} onOk={onRegistrationModalOk} />
        </div>
    )
}

