import Col from "antd/lib/col";
import Duration from "Components/Duration";
import {Registration} from "Apis/Models/Registration.type";
import moment from "moment";
import FlexRow from "Components/FlexRow";
import styles from './RegistrationCardListItemRow.module.scss';
import { useEditRegistrationModal } from "Pages/Registrations/Components/Providers/EditRegistrationModalProvider";
import { useCloneRegistration } from "Apis/RegistrationsApi";
import PlayIcon from "Assets/Icons/PlayIcon";
import { useTranslation } from "react-i18next";

interface Props {
    registration: Registration,
}
const RegistrationCardListItemRow = (props: Props) => {

    const { t } = useTranslation();

    const { selectRegistration } = useEditRegistrationModal();
    const { mutateAsync: cloneRegistrationMutation } = useCloneRegistration();

    const editRegistration = () => {
        selectRegistration(props.registration);
    }

    const cloneRegistrationHandler = async () => {
        await cloneRegistrationMutation(props.registration);
    }

    return (
        <>
            <Col xs={21} sm={8} onClick={editRegistration}>
                <div className={`${styles.column} ${!props.registration.description && styles.blank}`}>
                    {props.registration.description ? (
                        <div className={styles.descriptionText} key={props.registration.id}>{props.registration.description.length > 80 ? `${props.registration.description.substring(0, 90)}...` : props.registration.description}</div>
                    ) : (
                        <div className={`${styles.descriptionText} ${styles.blank}`}>
                            {t('registrations:noDescriptionEntered')}
                        </div>
                    )}
                </div>
            </Col>
            <Col xs={0} sm={8} onClick={editRegistration}>
                <div className={`${styles.column} ${!props.registration.project && styles.blank}`}>
                    {props.registration.project ? (
                        `${props.registration.project.customer!.name} - ${props.registration.project.name}`
                    ) :
                        t('registrations:noProjectSelected')
                    }
                </div>
            </Col>
            <Col xs={0} sm={3} onClick={editRegistration}>
                <div className={styles.column}>
                    {moment(props.registration.startedAt).format('HH:mm')} - {moment(props.registration.stoppedAt).format('HH:mm')}
                </div>
            </Col>
            <Col span={3} onClick={editRegistration}>
                <FlexRow justify={"end"}>
                    <Duration style={{fontSize: '1.1em', fontWeight: 700, color: '#4D576D'}} duration={props.registration.duration} />
                </FlexRow>
            </Col>
            <Col xs={0} sm={3} md={2}>
                <FlexRow justify={"end"}>
                    <PlayIcon style={{cursor: 'pointer'}} height={25} width={25} onClick={cloneRegistrationHandler} />
                </FlexRow>
            </Col>
            <Col className={styles.separator} span={24}></Col>
        </>
    )
}

export default RegistrationCardListItemRow;

